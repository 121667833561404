@font-face {
  font-family: 'Raleway';
  src: url('./assets/fonts/Raleway-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: url('./assets/fonts/Raleway-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: url('./assets/fonts/Raleway-ExtraBold.ttf');
  font-weight: bolder;
  font-style: normal;
}

body {
  background-color:#121212;
  color: white;
}